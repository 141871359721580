/*

  There are some conflicts between markdown file and this css.
  We need to clean the default!
*/

.sidebar-div {
  border-bottom: 1px solid #262626;
  border-radius: 5px;
}

.sidebar-div:hover {
  background-color: #ae67fa;
}

.sidebar-div:hover,
.sidebar-div:focus,
.sidebar-div:active {
  -webkit-transform: scale(1.01);
  transform: scale(1.01);
}

.sidebar-link-item {
  text-align: left;
  line-height: 2;
  font-size: 18px;
  text-transform: capitalize;
  cursor: pointer;
  padding-left: 0.5;
  margin: 0 1rem;
  list-style-type: none;
  grid-template-rows: auto 1fr auto;
  transition: all 0.3s linear;
}

.sidebar-container {
  /* display: flex; */
  position: fixed;
  left: 0;
  flex-direction: column;
  background: var(--color-footer);
  padding: 1rem;
  right: 0;
  top: 70px;
  margin-top: 1rem;
  min-width: 200px;
  border-radius: 5px;
  z-index: 1;
}

.sidebar-link-item p {
  padding: 0.1rem 0;
  color: #d9d9d9;
  font-weight: 500;
  text-transform: capitalize;
  font-family: var(--font-family);
  font-size: 17px;
}

@media screen and (max-width: 1104px) {
  .sidebar-div {
    margin-left: 4rem;
  }
}

@media screen and (max-width: 772px) {
  .sidebar-div {
    margin-left: 0;
  }
  .sidebar-container {
    min-width: 120px;
  }
}
