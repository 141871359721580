.FAQ {
  display: flex;
  flex-direction: column;
  font-family: var(--font-family);
}

.FAQ-heading {
  font-weight: 800;
  font-size: 50px;
  color: #bfbfbf;
}

.FAQ-question-items {
  display: flex;
  flex-direction: column;
  font-size: 20px;
  padding: 0.5rem;
}

@media screen and (max-width: 768px) {
  .FAQ-questions {
    font-size: 16px;
    padding-left: 0rem;
  }
}
