h1 {
  all: initial;
}

.locations {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.map {
  width: 714px;
  height: 455px;
}

.description {
  display: flex;
  flex-direction: column;
  max-width: 479px;
  max-height: 400px;
  padding-left: 5rem;
  justify-content: center;
  font-family: var(--font-family);
}

.description h1 {
  font-size: 38px;
  padding-bottom: 4rem;
}

.description p {
  font-style: normal;
  font-weight: 400;
  font-size: 200px;
  color: #81afdd;
}

.description h2 {
  color: #d9d9d9;
}

.description #join-us {
  color: #ff8a71;
}

@media screen and (max-width: 1105px) {
  .locations {
    display: block;
  }

  .map {
    margin: auto;
    max-width: 100%;
    max-height: 100%;
  }

  .description {
    align-items: center;
    max-width: 100%;
    padding-left: 0rem;
    max-height: auto;
  }

  .description h2 {
    font-size: 18px;
    text-align: center;
  }

  .description h1 {
    font-size: 38px;
    padding-bottom: 2rem;
    text-align: center;
    padding-top: 1rem;
  }
}

@media screen and (max-width: 550px) {
  .map {
    max-height: 300px;
  }

  .description {
    text-align: center;
  }

  .description h1 {
    font-size: 28px;
    text-align: center;
  }
}
