.footer {
  border: 1px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: var(--color-footer);
}

.footer-btn p {
  box-sizing: border-box;
  font-family: var(--font-family);
  font-size: 18px;
  line-height: 21px;

  color: #d9d9d9;
  word-spacing: 2px;
}

.footer-links {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  flex-direction: row;

  width: 100%;
  text-align: left;
}

.footer-links div {
  width: 250px;
  margin: 1rem;
}

.footer-links_logo {
  display: flex;
  flex-direction: column;
}

.footer-links_logo img {
  width: 88px;
  height: 88px;
}

.footer-links_logo p {
  font-family: var(--font-family);
  font-size: 14px;
  line-height: 0.5;
  cursor: pointer;
  color: #d9d9d9;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

.footer-links_div {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}

.footer-links_div h4 {
  font-family: var(--font-family);
  color: #d9d9d9;
}

.footer-links_div p {
  padding-left: 0.5rem;
  font-family: var(--font-family);
  font-size: 14px;
  line-height: 14px;
  color: #d9d9d9;

  margin: 0.5rem 0;
  cursor: pointer;
}

.footer-copyright {
  margin-top: 2rem;
  text-align: center;
  width: 100%;
}

.footer-copyright p {
  /* font-family: var(--font-family); */
  font-size: 12px;
  line-height: 14px;
  font-feature-settings: "liga" off;
  color: #bfbfbf;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

.footer-links_div .icon {
  color: #bfbfbf;
  margin: 0.1rem;
  padding-left: 0.5rem;
  cursor: pointer;
}

@media screen and (max-width: 756px) {
  .footer-links {
    text-align: start;
  }

  .footer-links_div p {
    font-size: 12px;
  }

  .footer-links div {
    margin: 0.5rem;
  }
}
