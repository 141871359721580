.goal p {
  all: initial;
}

.notion {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-around;
}

.goal {
  display: flex;
  flex-direction: column;
  width: 50%;
}

.slogan {
  font-weight: 800;
  font-size: 36px;
  line-height: 40px;
  padding-bottom: 1rem;
  text-align: center;
}

.goal p {
  max-width: 100%;
  font-weight: 500;
  font-size: 26px;
  line-height: 1.5;
  padding-top: 2rem;
  text-align: justify;
  color: #ff8a71;
  font-family: var(--font-family);
}

.bannerImage {
  padding-left: 0.1rem;
}

@media screen and (max-width: 1280px) {
  .notion {
    display: block;
  }

  .slogan {
    display: none;
  }

  .goal {
    margin: auto;
    width: 80%;
    font-size: 22px;
  }

  .goal p {
    padding-top: 0rem;
    padding-bottom: 1rem;
  }

  .bannerImage {
    max-width: 100%;
    max-height: 100%;
    margin: auto;
  }
}

@media screen and (max-width: 1024px) {
  .goal p {
    padding-top: 0rem;
    padding-bottom: 1rem;
    font-size: 22px;
  }
  .bannerImage {
    padding-left: 0rem;
  }
}

@media screen and (max-width: 768px) {
  .goal p {
    padding-top: 0rem;
    padding-bottom: 1rem;
    font-size: 18px;
    font-weight: 400;
  }

  .bannerImage {
    padding-left: 0rem;
    height: 300px;
  }
}
