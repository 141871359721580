.cta {
  border: 0.5px solid;
  display: flex;
  justify-content: center;
  border-radius: 1rem;
  padding-left: 0.5rem;
  border-color: #01050d;
}

.cta__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.cta__content h3 {
  font-family: var(--font-family);
  font-weight: 600;
  font-size: 30px;
  line-height: 1.5;
  justify-content: center;
  color: #f0f0f0;
}

.cta__content p {
  font-family: var(--font-family);
  font-weight: 500px;
  font-size: 12px;
  color: #fff;
  justify-content: center;
}

.cta__input {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 1104px) {
  .input::placeholder {
    font-size: 12px;
  }

  .cta {
    margin: 0 0;
  }

  .cta_submit button {
    font-size: 16px;
  }
}

@media screen and (max-width: 768px) {
  .cta_submit {
    width: 90%;
    margin: 1rem 0rem;
  }

  input {
    padding: 0 0;
  }

  .section__padding {
    padding: 1rem 1rem;
  }

  .cta {
    display: flex;
  }

  .cta_submit button {
    font-size: 16px;
  }

  .cta__content h3 {
    font-size: 18px;
  }

  input {
    font-size: 14px;
  }
}

@media screen and (max-width: 700px) {
  .cta_submit {
    width: 100%;
  }

  .section_padding {
    padding: 0.5rem;
  }

  .cta_submit button {
    font-size: 16px;
  }
}
