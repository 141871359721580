.services {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.service__title {
  text-align: left;
  font-size: 50px;
  font-weight: 800;
}

.service-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-top: 1rem;
}

.service-container .feature {
  display: inline-block;
}

.service-container .feature-title {
  flex: 1;
  margin: 1rem;
  min-width: 210px;
  display: unset;
  flex-direction: column;
}

.service-container .feature-title div {
  width: 60px;
}

.service-container .feature-text {
  margin-top: 0.5rem;
  line-height: 1.5;
}

.service-container .feature-text p {
  color: #8c8c8c;
  line-height: 1.5;
  font-size: 18px;
}

@media screen and (max-width: 1280px) {
  .service-container {
    margin-top: 0rem;
  }

  .service-container .feature-text p {
    font-size: 16px;
  }
}

@media screen and (max-width: 950px) {
  .service__title {
    font-size: 45px;
  }

  .service-container {
    display: block;
  }

  .service-container .feature {
    flex-direction: row;
  }

  .service-container .feature-text {
    /* text-align: justify; */
    margin-top: 0rem;
    width: 450px;
  }
}

@media screen and (max-width: 550px) {
  .service__title {
    font-size: 35px;
  }
  .service-container .feature {
    flex-direction: column;
  }

  .service-container .feature-text {
    width: 310px;
  }
}

@media screen and (max-width: 440px) {
  .service-container .feature {
    margin: 0;
  }
}
