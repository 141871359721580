.SingleQuestion {
  display: flex;
  flex-direction: column;
  font-family: var(--font-family);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  margin-top: 0.5rem;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.SingleQuestion:hover,
.SingleQuestion:focus,
.SingleQuestion:active {
  -webkit-transform: scale(1.01);
  transform: scale(1.01);
}

.SingleQuestion-header {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  right: 0;
  cursor: pointer;
}

.SingleQuestion-title {
  font-size: 20px;
  font-weight: 400;
  color: #d9d9d9;
  margin: 1rem;
  font-family: var(--font-family);
}

.SingleQuestion-button {
  background: none;
  border: none;
  color: #fff;
  font-size: 20px;
  cursor: pointer;
  padding-right: 0;
}

.SingleQuestion-info {
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  margin-top: 0.5rem;
}

.SingleQuestion .info {
  display: flex;
  flex-direction: column;
  padding: 0.5rem 1rem;
  font-family: var(--font-family);
  border: 1px solid #262626;
  color: #8c8c8c;
  line-height: 1.8;
  border-radius: 10px;
  margin: 0.5rem;
  font-weight: 500;
}

@media screen and (max-width: 768px) {
  .SingleQuestion-title {
    font-size: 16px;
  }
}
