.feature {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  margin: 1rem;
}

.feature-title {
  flex: 1;
  max-width: 180px;
  margin-right: 2rem;
}

.feature-title topbar {
  width: 44px;
  height: 3px;
  background: var(--gradient-bar);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  margin-bottom: 0.25rem;
  display: block;
}

.feature-title h1:before {
  background-color: #afd135;
  content: "";
  height: 5px;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  -webkit-transition: opacity 0.3s ease-in-out;
  -o-transition: opacity 0.3s ease-in-out;
  transition: opacity 0.3s ease-in-out;
  width: 100%;
}

.feature-title h1 {
  font-family: var(--font-family);
  font-weight: 800;
  font-size: 22px;
  line-height: 24px;
  letter-spacing: -0.04em;
  color: #fff;
}

.feature-text {
  flex: 2;
  max-width: 390px;
  display: flex;
}

.feature-text p {
  font-family: var(--font-family);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: var(--color-text);
}
