.BH__blog-container_article {
  width: 100%;
  height: 100%;
  display: flex;
  border-radius: 0.25rem;
  flex-direction: column;
  background: var(--color-footer);
}

.BH__blog-container_article-image img {
  width: 100%;
  height: 100%;
  border-radius: 0.5rem 0.5rem 0 0;
  filter: grayscale(100%); /* Make the image grayscale */
  transition: filter 0.5s ease; /* Smooth transition when hovering */
}

.BH__blog-container_article-image img:hover {
  filter: grayscale(0%); /* Remove grayscale on hover */
}

.BH__blog-container_article-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1rem 1.5rem;
  height: 100%;
}

.BH__blog-container_article-content p {
  font-family: var(--font-family);
  font-style: normal;
  font-weight: bold;
  font-size: 11.649px;
  line-height: 35px;
  color: #8c8c8c;
}

.BH__blog-container_article-content h3 {
  font-family: var(--font-family);
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 1;
  color: #bfbfbf;
  cursor: pointer;
}

.BH__blog-container_article-content h4 {
  font-family: var(--font-family);
  font-size: 16px;
  padding-top: 0.5rem;
  text-align: end;
  opacity: 0;
}

.BH__blog-container_article-content:hover > h4 {
  color: #597ef7;
  cursor: pointer;
  opacity: 1;
  -webkit-transition: opacity 0.25s ease-in-out 0s;
  transition: opacity 0.25s ease-in-out 0s;
}

.BH__blog-container_article-content p:last-child {
  cursor: pointer;
}

@media screen and (max-width: 550px) {
  .BH__blog-container_article-content h3 {
    font-size: 80;
    line-height: 25px;
  }
}
