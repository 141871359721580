.header {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-auto-columns: minmax(100px, auto);
}

.content {
  display: flex;
  flex: 1;
  justify-content: start;
  align-items: flex-between;
  grid-column: 1 / 3;
  flex-direction: column;
  padding-top: 2rem;
  padding-left: 2rem;
  margin-right: 5rem;
}

.content h1 {
  font-family: var(--font-family);
  font-weight: 1000;
  font-size: 88px;
  line-height: 60px;
  letter-spacing: -0.02em;
  line-height: 1.8;
  color: white;
}

.content h2 {
  font-family: var(--font-family);
  font-weight: 1000;
  font-size: 50px;
  line-height: 48px;
  letter-spacing: -0.02em;
  line-height: 1.8;
  color: white;
}

.content p {
  padding-right: 12rem;
  font-family: var(--font-family);
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  color: #8c8c8c;
  margin-top: 2rem;
  line-height: 1.6;
}

/* add a new span to avoid SEO complaints */
.screen-reader-text {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  -webkit-clip-path: inset(50%);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute !important;
  width: 1px;
  word-wrap: normal !important;
  word-break: normal;
}

.button {
  margin: 4rem 2rem 2rem 2rem;
}

.button1 {
  width: 150px;
  height: 60px;
  font-size: 18px;
  margin-left: 4rem;
  padding: 1rem;
  border-radius: 20px;
  color: #fff;
  border-color: rgb(187, 222, 242, 1);
  border-width: 1px;
  background-color: #000000;
  cursor: pointer;
}

.button1:hover {
  color: #000000;
  background: #fff;
}

.button2 {
  width: 155px;
  height: 60px;
  font-size: 18px;
  margin-left: 5rem;
  padding: 1rem 0rem;
  border-radius: 20px;
  background-color: #e25ef8;
  color: #000000;
  cursor: pointer;
}

.button2:hover {
  background: #000000;
  color: #e25ef8;
}

.img {
  display: block;
  margin: auto auto;
  filter: grayscale(60%);
}

@media (min-width: 1402px) and (max-width: 1680px) {
  .content {
    padding-top: 5rem;
  }

  .content h1 {
    font-size: 77px;
  }

  .content h2 {
    font-size: 45px;
  }

  .img img {
    width: 98%;
  }

  .button {
    margin: 6rem 2rem 2rem -1rem;
  }
}

@media (min-width: 1254px) and (max-width: 1401px) {
  .content {
    padding-top: 3rem;
  }

  .content h1 {
    font-size: 60px;
  }

  .content h2 {
    font-size: 40px;
  }

  .content p {
    margin-top: 3rem;
    font-size: 16px;
    padding-right: 2rem;
  }

  .img img {
    width: 120%;
  }

  .button {
    margin: 4rem 2rem 2rem 0rem;
  }

  .button1 {
    margin-left: 1rem;
    margin-top: 0rem;
    margin-right: 0rem;
    font-size: 16px;
    width: 120px;
    height: 50px;
  }

  .button2 {
    font-size: 16px;
    width: 120px;
    height: 50px;
  }
}

@media (min-width: 1105px) and (max-width: 1253px) {
  .content {
    padding-top: 9rem;
  }

  .content h1 {
    font-size: 55px;
  }

  .content h2 {
    font-size: 35px;
  }

  .content p {
    padding-right: 0rem;
  }

  .button {
    margin: 6rem 2rem 2rem -2rem;
  }

  .img img {
    width: 120%;
  }

  .button {
    margin: 7rem 2rem 2rem -1rem;
  }

  .button1 {
    margin-left: 1rem;
    margin-top: 0rem;
    margin-right: 0rem;
    font-size: 16px;
    width: 140px;
    height: 50px;
  }

  .button2 {
    font-size: 16px;
    width: 140px;
    height: 50px;
  }
}

/*TODO: why the media query of height: 60vh is not working for edge browser*/
@media (min-width: 769px) and (max-width: 1104px) {
  .content {
    padding-top: 3rem;
    margin-right: 0rem;
  }

  .content h1 {
    font-size: 43px;
  }

  .content h2 {
    font-size: 30px;
  }

  .content p {
    padding-right: 2rem;
    padding-top: 1.2rem;
  }

  .button {
    margin: 4rem 0rem 0rem 0rem;
    display: flex;
  }

  .button1 {
    margin-left: 0rem;
    font-size: 15px;
    width: 130px;
    height: 50px;
    padding: 0;
  }

  .button2 {
    margin-left: 2rem;
    font-size: 15px;
    width: 130px;
    height: 50px;
    padding: 0;
  }

  .img img {
    width: 120%;
  }
}

@media screen and (max-width: 768px) {
  .header {
    display: block;
  }

  .content {
    padding-left: 0rem;
    margin-right: 0rem;
  }
  .content h1 {
    font-size: 43px;
  }

  .content h2 {
    font-size: 35px;
  }

  .content p {
    padding: 0 0;
  }

  .loop__text {
    display: flex;
  }

  .button {
    margin: 4rem 0rem 2rem 0rem;
    display: flex;
  }

  .button1 {
    margin-left: 0rem;
    height: 50px;
    width: 130px;
    font-size: 16px;
    padding: 0;
  }

  .button2 {
    margin-left: 2rem;
    height: 50px;
    width: 130px;
    font-size: 16px;
    padding: 0;
  }

  .img img {
    display: none;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
  }
}

@media screen and (max-width: 550px) {
  .header {
    display: block;
  }

  .content {
    padding-left: 0rem;
    margin-right: 0rem;
    padding-top: 0rem;
  }
  .content h1 {
    font-size: 36px;
  }

  .content h2 {
    font-size: 28px;
  }

  .content p {
    margin-top: 3rem;
    padding: 0 0;
    line-height: 1.5;
  }

  .loop__text {
    display: flex;
  }

  .button {
    margin: 3rem 0rem 2rem 0rem;
    display: flex;
  }

  .button1 {
    margin-left: 0rem;
    height: 50px;
    font-size: 14px;
    padding: 0;
  }

  .button2 {
    margin-left: 2rem;
    height: 50px;
    font-size: 14px;
    padding: 0;
  }

  .img img {
    display: none;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
  }
}

@media screen and (max-width: 410px) {
  .content h1 {
    font-size: 33px;
  }

  .content h2 {
    font-size: 24px;
  }
}
