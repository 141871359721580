@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap");

/*setup the css variable*/
:root {
  --font-family: "Manrope", sans-serif;
  --gradient-text: linear-gradient(89.97deg, #ae67fa 1.84%, #f49867 102.67%);
  --gradient-bar: linear-gradient(103.22deg, #ae67fa -13.86%, #f49867 99.55%);
  /*Back ground color*/
  --color-bg: #01050d;
  --color-footer: #0d121d;
  --color-blog: #042c54;
  --color-text: #d9d9d9;
  --color-subtext: #ff8a71;
  --clr-white: #fff;
  --clr-red-dark: hsl(360, 67%, 44%);
  --clr-red-light: hsl(360, 71%, 66%);
  --clr-green-dark: hsl(125, 67%, 44%);
  --clr-green-light: hsl(125, 71%, 66%);
  --clr-black: rgb(168, 127, 127);
  --transition: all 0.3s linear;
  --spacing: 0.1rem;
  --radius: 0.25rem;
  --light-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  --dark-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  --max-width: 1170px;
  --fixed-width: 620px;
  /*This is for nav bar fixed position*/
  padding-top: 6rem;
}
