/*TODO: the markdown css will affect the p tag in this css*/
.title {
  font-weight: 800;
  font-size: 50px;
  font-family: var(--font-family);
  color: #fff;
  align-content: center;
  text-align: center;
}

.privacy {
  font-family: var(--font-family);
  color: white;
  font-size: 12px;
  text-align: end;
  color: #bfbfbf;
  padding-top: 1rem;
  padding-right: 1rem;
}

@media screen and (max-width: 765px) {
  .title {
    font-size: 40px;
  }
}
