.resource {
  display: flex;
  flex-direction: column;
}

.resource-heading {
  width: 100%;
  text-align: left;
  margin-bottom: 2rem;
}

.resource-heading h1 {
  font-family: var(--font-family);
  font-style: normal;
  font-weight: 800;
  font-size: 50px;
  line-height: 50px;
  letter-spacing: -0.04rem;
}

.resource-container {
  display: flex;
  flex-direction: row;
}

.resource-container_GroupA {
  flex: 0.75;
  margin-right: 2rem;
  align-items: center;
}

.resource-container_GroupB {
  flex: 1;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 2rem;
  align-items: center;
}

.resource-container_article-content h3 {
  font-family: var(--font-family);
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  line-height: 30px;
  color: #fff;
  cursor: pointer;
}

.resource-container_article-content p {
  font-family: var(--font-family);
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 30px;
  color: #fff;
}

.BH__blog-container_article-content h3 {
  line-height: 1.3;
}

@media screen and (max-width: 1281px) {
  .resource-container {
    padding: 0 0;
  }

  .BH__blog-container_article-content h3 {
    font-size: 16px;
    font-weight: 600;
  }
}

@media screen and (max-width: 950px) {
  .resource-container {
    padding: 0 0;
    flex-direction: column-reverse;
  }

  .resource-container_GroupA {
    margin: 2rem 0;
  }

  .resource-container_GroupA .BH__blog-container_article {
    width: 48%;
  }

  .resource-container_GroupA .BH__blog-container_article-image {
    height: 30%;
  }

  .BH__blog-container_article-content h3 {
    font-size: 14px;
  }
}

/*TODO: here we changed the breakpoints to 820px instead of 768px
 * to accomodate Safari browser
 * Need to investigate more
 * */
@media screen and (max-width: 820px) {
  .resource-container_GroupA .BH__blog-container_article {
    width: 100%;
  }
  .resource-heading h1 {
    font-size: 40px;
  }
  .resource-container_GroupB {
    grid-template-columns: repeat(1, 1fr);
  }

  .resource-container_GroupA {
    width: 100%;
    height: 40%;
  }
}
