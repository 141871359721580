.section__padding_nav {
  width: 100%;
  padding: 1.5rem 6rem;
  background-color: #01050d;
  opacity: 95%;
  z-index: 1000;
  position: fixed;
  top: 0;
  /* border-block-end: 0.1px solid #262626; */
}

.section__padding_nav.with-line {
  border-bottom: 0.1px solid #262626;
  border-radius: 0px 0px 10px 10px;
  box-shadow: 5px rgba(178, 178, 178, 0.25);
}

.nav-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav-logo img {
  width: 188px;
  height: 60px;
}

.navbar-header-links {
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav-links-container {
  display: flex;
  flex-direction: row;
}

.nav-signin button,
.submenu-btn button {
  font-size: 18px;
  font-weight: 500;
  /* font-weight: bold; */
  padding: 0.25rem 0.75rem;
  border-radius: 5px;
  border: hidden;
  background-color: #e25ef8;
  border-color: #e25ef8;
  color: #000000;
  cursor: pointer;
  transition: var(--transition);
  white-space: nowrap;
}

.nav-signin button:hover {
  background: #000000;
  color: #e25ef8;
}

.nav-links-container p {
  color: #fff;
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  text-transform: capitalize;
  width: 8rem;
  margin: 0 1rem;
  cursor: pointer;
}

.signin-btn {
  display: flex;
}

.submenu-toggle {
  display: none;
}

.nav-links {
  display: flex;
  list-style-type: none;
}

.link-btn {
  letter-spacing: 1px;
  width: 10rem;
  text-align: center;
}

.text-box {
  position: relative; /* Ensure the pseudo-element is positioned relative to this element */
}

.text-box::after {
  content: "";
  position: absolute; /* Position the line relative to the text-box */
  bottom: -5px; /* Position the line at the bottom of the text-box */
  left: 0; /* Start the line from the left edge of the text-box */
  height: 2px;
  width: 0; /* Initially set the width to 0 */
  background: #e25ef8;
  transition: width 0.3s ease; /* Add transition effect */
}

/* Expand the width of the line on hover */
/* Stay focus when select on it */
.text-box:hover::after {
  width: 100%;
}
.text-box:focus::after {
  width: 100%;
}

.text-box.clicked::after {
  width: 100%;
}

.submenu-signin {
  display: none;
}

@media screen and (max-width: 1331px) {
  .nav-links-container {
    padding: 0rem 0rem;
  }

  .nav-links-container p {
    margin: 0 0;
  }
}

@media screen and (max-width: 1104px) {
  .nav-links-container {
    display: none;
  }

  .section__padding_nav.with-line {
    border-bottom: 1px solid #01050d;
  }

  .submenu-toggle {
    display: flex;
    justify-content: flex-end;
  }

  .burger-menu {
    display: block;
    color: #fff;
    font-size: 30px;
    align-items: center;
    padding-left: 1rem;
    padding-top: 0.5rem;
  }

  .nav-signin button,
  .submenu-btn button {
    font-weight: 500;
    font-size: 16px;
  }
}

@media screen and (max-width: 769px) {
  .section__padding_nav {
    width: 100%;
    padding: 1.5rem 2rem;
  }

  .section__padding_nav.with-line {
    border-bottom: 1px solid #01050d;
  }

  .nav-signin {
    display: none;
  }

  .submenu-signin {
    display: block;
    font-weight: 500;
  }
}

@media screen and (max-width: 500px) {
  .section__padding_nav {
    width: 100%;
    padding: 1.5rem 2rem;
  }
}
