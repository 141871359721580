.bannerImage {
  width: 510px;
  height: 460px;
}

@media screen and (max-width: 765px) {
  .bannerImage {
    height: 320px;
  }
}
